import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { BehaviorSubject, firstValueFrom, Observable } from "rxjs";
import { env as environment } from "../../../environment/environment";
import { StoreService } from "./store.service";
import { Utils } from "../helpers/utils";

export class ContentType {
  public static JSON = "application/json";
  public static CSV = "text/csv";
  public static URLEncoded = "application/x-www-form-urlencoded";
}

@Injectable({
  providedIn: "root",
})
export class RequestService {
  private API_URL = environment.API_URL;
  private USER_API_URL = this.API_URL + "/users";
  private BOOKING_API_URL = this.API_URL + "/appointments";
  private COUNTRIES_API_URL = this.API_URL + "/countries";
  private ALLERGIES_API_URL = this.API_URL + "/allergies";
  private SPECIALNEEDS_API_URL = this.API_URL + "/special_need";
  private KEYWORDS_API_URL = this.API_URL + "/keywords";
  private MEDIA_API_URL = this.API_URL + "/media";
  private EDUCATION_LEVEL_API_URL = this.API_URL + "/education_level";
  private PAGES_API_URL = this.API_URL + "/pages";
  private BLOGS_API_URL = this.API_URL + "/blogs";
  private CATEGORY_API_URL = this.API_URL + "/categories";
  private CONTACT_API_URL = this.API_URL + "/contact";
  private GALLERY_API_URL = this.API_URL + "/gallery";
  private PRODUCT_API_URL = this.API_URL + "/products";
  private LANGUAGES_API_URL = this.API_URL + "/languages";
  private PAYMENT_API_URL = this.API_URL + "/create-payment-intent";
  private INVOICES_API_URL = this.API_URL + "/stripe/invoices";
  private ORDER_API_URL = this.API_URL + "/orders";

  // private v1ServerUrl = environment.serverV1Url;
  // private orgType = environment.orgType;
  // protected productId = environment.productId;
  // private checkoutReqUrl = environment.serverUrl;
  // private loading: boolean = false;
  private token: any = "";
  private userType: string = "default";
  public loginEnteredIds = undefined;
  public orgId = undefined;
  public appId = undefined;
  public locId = undefined;
  public lang = "en";
  // public environmentserverHostUrl = environment.serverUrl;
  public serverHostUrl =
    location.protocol +
    "//" +
    location.hostname +
    (location.port ? ":" + location.port : "");
  public appStatusSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>("");
  public authenticatedUser: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isNBCA: boolean = false;
  public pageOrganization: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );
  private cachedObj = {};
  private checkoutCachedObj = {};
  ids: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentUserSubject: BehaviorSubject<any | undefined> =
    new BehaviorSubject<any | undefined>(undefined);
  public _currentUser: any | undefined = undefined;
  set currentUser(currentUser: any | undefined) {
    if (currentUser) {
      this._currentUser = currentUser;
      let userObject: any = currentUser;
      this.userType = currentUser.type || "default";
      this.store.init("default");
      this.setToken(userObject.token);
      // this.currentUser = userObject;
      this.currentUserSubject.next(userObject);
      // this.permissionsService.flushPermissions();
      // if (currentUser.isSuperAdmin) {
      //   this.permissionsService.addPermission(environment.customKeys.roleAdmin);
      // }
      // for (let res of userObject.resources) {
      //   this.permissionsService.addPermission(res._id);
      // }
    } else {
      this._currentUser = undefined;
      this.orgId = undefined;
      this.currentUserSubject.next(undefined);
      this.token = "";
      this.userType = "default";
      this.store.init("default");
      // this.permissionsService.flushPermissions();
    }
  }
  get currentUser(): any | undefined {
    if (this.currentUserSubject.getValue() == undefined) {
      let _temp = this.store.get("currentUser");
      this.currentUserSubject.next(_temp);
      this.authenticatedUser.next(true);
    }
    return this.currentUserSubject.getValue();
  }
  public updateCurrentUser(newData: any) {
    let objectUser = Object.assign(this._currentUser, newData);
    objectUser.role = objectUser.roles.includes("marveler")
      ? "marveler"
      : "counselor";
    this.currentUser = objectUser;
  }
  // public updatePermissions(resources: any) {
  //   this.permissionsService.flushPermissions();
  //   this.permissionsService.addPermission(resources._id);
  // }
  public updateUserData(key: any, value: any) {
    let userObject: any = this.currentUserSubject.getValue();
    // let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    userObject[key] = value;
    this.store.set("currentUser", userObject);
    localStorage.setItem("currentUser", JSON.stringify(userObject));
    this.currentUserSubject.next(userObject);
  }
  constructor(
    private utils: Utils,
    public store: StoreService,
    private router: Router,
    private http: HttpClient // private permissionsService: NgxPermissionsService
  ) {
    this.store.init("default");
  }
  public loggedIn() {
    if (this.currentUser) {
      return true;
    } else {
      return false;
    }
  }
  public getUserType() {
    return this.userType;
  }
  public getUserId() {
    if (this.currentUser && this.currentUser.hasOwnProperty("_id")) {
      return this.currentUser["_id"];
    } else {
      return "";
    }
  }
  public setToken(token: any) {
    this.token = token;
  }
  public getToken() {
    return this.token;
  }
  private async jsonRequest(
    urlString: string,
    method: string = "POST",
    postBody: any = undefined,
    contentType: string = ContentType.JSON
  ) {
    if (urlString) {
      let url: string = urlString || "";

      let headers: any = {
        Accept: "application/json",
      };
      if (contentType) {
        headers["Content-Type"] = contentType;
      }

      let t = this.store.get("token") || null;

      if (this.token || t) {
        headers["Authorization"] = t || this.token;
      }

      let httpOptions: any = {
        responseType: "json",
        headers: new HttpHeaders(headers),
        credentials: "include",
        method: method,
        body: postBody,
      };

      // let bodyString = postBody;
      if (method === "POST") {
        let postRequest = this.http.post<any>(url, postBody, httpOptions);
        let response = await firstValueFrom(postRequest);
        return response;
      } else if (method === "GET") {
        let postRequest = this.http.get<any>(url, httpOptions);
        let response = await firstValueFrom(postRequest);
        return response;
      } else if (method === "PUT") {
        let postRequest = this.http.put<any>(url, postBody, httpOptions);
        let response = await firstValueFrom(postRequest);
        return response;
      } else if (method === "DELETE") {
        let postRequest = this.http.delete<any>(url, httpOptions);
        let response = await firstValueFrom(postRequest);
        return response;
      } else if (method === "PATCH") {
        let postRequest = this.http.patch<any>(url, postBody, httpOptions);
        let response = await firstValueFrom(postRequest);
        return response;
      } else if (method === "OPTIONS") {
        let postRequest = this.http.request<any>(method, url, httpOptions);
        let response = await firstValueFrom(postRequest);
        return response;
      }
    }
    return undefined;
  }
  public async login(email: string, password: string) {
    let urlStr = this.USER_API_URL + "/login?depth=1";
    const body = {
      email,
      password,
    };
    let response: any = await this.jsonRequest(urlStr, "POST", body);
    if (response && response.user?.email && response.user?.roles) {
      let userObject = response.user;
      this.setToken(response.token);
      userObject["token"] = response.token;
      userObject["role"] = userObject.roles.includes("marveler")
        ? "marveler"
        : "counselor";
      this.store.set("currentUser", userObject);
      this.store.set("token", `Bearer ${response.token}`);
      this.currentUser = userObject;
      this.authenticatedUser.next(true);
      return userObject;
      // callback(userObject, undefined, false);
      // localStorage.setItem("loggedInEmail", response.user.email);
      // localStorage.setItem("currentUserId", response.user.id);
      // localStorage.setItem("access_token", response.token);
      // localStorage.setItem("authToken", response.token);

      // this.loggedInEmail.next(response.user.email);
      // this.updateUserType(response.user.roles);
    }
  }
  async getUserBookingByStatus(status: string) {
    let id = this.currentUser?.id;
    let urlStr = `${this.BOOKING_API_URL}?where[host][equals]=${id}&where[status][equals]=${status}`;
    if (this.currentUser?.roles.includes("marveler")) {
      urlStr = `${this.BOOKING_API_URL}?where[client][equals]=${id}&where[status][equals]=${status}`;
    }
    let response: any = await this.jsonRequest(urlStr, "GET");
    return response;
  }
  async getCountries() {
    let urlStr = `${this.COUNTRIES_API_URL}?limit=300&sort=name`;
    let response: any = await this.jsonRequest(urlStr, "GET");
    return response;
  }
  async getAllergies() {
    let urlStr = `${this.ALLERGIES_API_URL}?limit=300&sort=name`;
    let response: any = await this.jsonRequest(urlStr, "GET");
    return response;
  }
  async deleteAllergies(allergie_id: string) {
    let urlStr = `${this.ALLERGIES_API_URL}/${allergie_id}`;
    let response: any = await this.jsonRequest(urlStr, "DELETE");
    return response;
  }
  async getSpecialNeeds() {
    let urlStr = `${this.SPECIALNEEDS_API_URL}?limit=300&sort=name`;
    let response: any = await this.jsonRequest(urlStr, "GET");
    return response;
  }
  async deleteSpecialNeeds(SPN_id: string) {
    let urlStr = `${this.SPECIALNEEDS_API_URL}/${SPN_id}`;
    let response: any = await this.jsonRequest(urlStr, "DELETE");
    return response;
  }
  async getKeywords() {
    let urlStr = `${this.KEYWORDS_API_URL}?depth=2&draft=true&limit=500&page=1&sort=createdAt`;
    let response: any = await this.jsonRequest(urlStr, "GET");
    return response;
  }
  async updateUser(user: any) {
    let urlStr = `${this.USER_API_URL}/${user.id}`;
    let response: any = await this.jsonRequest(urlStr, "PATCH", user);

    response.doc["role"] = response.doc?.roles.includes("marveler")
      ? "marveler"
      : "counselor";

    this.store.set("currentUser", response.doc);
    this.currentUser = response.doc;
    this.authenticatedUser.next(true);
    return response;
  }
  async addMedia(formdata: FormData) {
    let response: any = await this.jsonRequest(
      `${this.MEDIA_API_URL}`,
      "POST",
      formdata
    );
    return response;
  }
  async getEducationLevel() {
    let urlStr = `${this.EDUCATION_LEVEL_API_URL}?limit=300&sort=title`;
    let response: any = await this.jsonRequest(urlStr, "GET");
    return response;
  }
  async getPages() {
    let urlStr = `${this.PAGES_API_URL}?depth=1&draft=false&locale=undefined`;
    let response: any = await this.jsonRequest(urlStr, "GET");
    return response;
  }
  async getBlogs() {
    let urlStr = `${this.BLOGS_API_URL}?depth=1&draft=false&locale=undefined`;
    let response: any = await this.jsonRequest(urlStr, "GET");
    return response;
  }
  async getCategories() {
    let urlStr = `${this.CATEGORY_API_URL}?depth=1&draft=false&locale=undefined`;
    let response: any = await this.jsonRequest(urlStr, "GET");
    return response;
  }
  async contact(body: any) {
    let urlStr = `${this.CONTACT_API_URL}`;
    let response: any = await this.jsonRequest(urlStr, "POST", body);
    return response;
  }
  async uploadMedia(formData: FormData) {
    let headers = {
      Authorization: this.store.get("token"),
      Accept: "application/json",
    };
    let httpOptions = {
      headers: new HttpHeaders(headers),
    };

    let postRequest = this.http.post<any>(
      this.MEDIA_API_URL,
      formData,
      httpOptions
    );
    let response = await firstValueFrom(postRequest);
    return response;
  }

  async updateMedia(id: string, formData: FormData) {
    let headers = {
      Authorization: this.store.get("token"),
      Accept: "application/json",
    };
    let httpOptions = {
      headers: new HttpHeaders(headers),
    };

    let postRequest = this.http.patch<any>(
      `${this.MEDIA_API_URL}/${id}`,
      formData,
      httpOptions
    );
    let response = await firstValueFrom(postRequest);
    return response;
  }

  // deleteMedia(id: string) {
  //   return this.jsonRequest(`${this.MEDIA_API_URL}/${id}`, "DELETE");
  // }

  // findGallery(id: string) {
  //   return this.jsonRequest(
  //     `${this.GALLERY_API_URL}?where[userId][equals]=${id}`,
  //     "GET"
  //   );
  // }

  async forgotPassword(email: string) {
    let urlStr = `${this.USER_API_URL}/forgot-password`;
    let response: any = await this.jsonRequest(urlStr, "POST", { email });
    return response;
  }
  async getProducts() {
    let urlStr = `${this.PRODUCT_API_URL}?limit=300&sort=name`;
    let response: any = await this.jsonRequest(urlStr, "GET");
    return response;
  }
  async findExplore() {
    let searchParams = JSON.parse(localStorage.getItem("searchParams") || "{}");

    let where: string = "?where[roles][equals]=counselor";
    if (searchParams) {
      // if (searchParams.selectedCity) {
      //   where += `&where[city][equals]=${searchParams.selectedCity}`;
      // }
      // if (searchParams.adultValue) {
      //   where += `&where[adults_limit][greater_than_equal]=${searchParams.adultValue}`;
      // }
      // if (searchParams.childrenValue) {
      //   where += `&where[children_limit][greater_than_equal]=${searchParams.childrenValue}`;
      // }
      // if (searchParams.infantValue) {
      //   where += `&where[infants_limit][greater_than_equal]=${searchParams.infantValue}`;
      // }
      // if (searchParams.petValue) {
      //   where += `&where[pets_limit][greater_than_equal]=${searchParams.petValue}`;
      // }
    }
    where += "&sort=-score&limit=300";

    const url = `${this.USER_API_URL}${where}`;
    let response: any = await this.jsonRequest(url, "GET");
    return response;
  }
  getConsIds() {
    return this.ids.asObservable();
  }
  setConsIds(data: any) {
    this.ids.next(data);
  }
  async getLanguages() {
    let response: any = await this.jsonRequest(
      `${this.LANGUAGES_API_URL}?limit=150`,
      "GET"
    );
    return response;
  }
  async createPaymentIntenet() {
    let response: any = await this.jsonRequest(this.PAYMENT_API_URL, "GET");
    return response;
  }

  async getInvoices() {
    let response: any = await this.jsonRequest(this.INVOICES_API_URL, "GET");
    return response;
  }
  public logout() {
    localStorage.clear();
    this.router.navigate(["/login"]);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }
  async getCounselor() {
    let urlStr = `${this.USER_API_URL}?where[roles][equals]=counselor&sort=-score`;
    let response: any = await this.jsonRequest(urlStr, "GET");
    return response;
  }
  async getUserById(id: string) {
    let urlStr = `${this.USER_API_URL}/${id}`;
    let response: any = await this.jsonRequest(urlStr, "GET");
    return response;
  }
  async findCompletedAppointment() {
    const today = new Date().toISOString().split("T")[0];
    let urlStr = `${this.BOOKING_API_URL}?where[host][equals]=${this.currentUser.id}&where[createdAt][gt]=${today}`;
    let response: any = await this.jsonRequest(urlStr, "GET");
    return response;
  }
  async registerUser(payload: Object) {
    const urlStr = `${this.USER_API_URL}`;
    const response: any = await this.jsonRequest(urlStr, "POST", payload);
    return response;
  }
  async verifyAccount(token: string) {
    const urlStr = `${this.USER_API_URL}/verify/${token}`;
    const response: any = await this.jsonRequest(urlStr, "GET");
    return response;
  }

  async forgetPassword(email: string) {
    const urlStr = `${this.USER_API_URL}/forgot-password`;
    const response: any = await this.jsonRequest(urlStr, "POST", { email });
    return response;
  }

  async resetPassword(body: any) {
    const urlStr = `${this.USER_API_URL}/reset-password`;
    const response: any = await this.jsonRequest(urlStr, "POST", body);
    return response;
  }
  async addOrder(payload: any) {
    const urlStr = `${this.ORDER_API_URL}`;
    const response: any = await this.jsonRequest(urlStr, "POST", payload);
    return response;
  }
}
