import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root', // Ensures it's available application-wide
})
export class Utils {
  constructor() {}

  // Encode a given string
  htmlEncode(text: string) {
    text = text.replace(/'/g, "&#39;");
    return text;
  }

  // Decode a given string
  htmlDecode(text: string) {
    text = text.replace("&#39;", "'");
    return text;
  }

  // Converting quotes to escaping quotes from the given string
  escapingQuotes(stringElem: string) {
    if (
      stringElem &&
      stringElem != "" &&
      stringElem != null &&
      typeof stringElem !== "undefined"
    ) {
      stringElem = stringElem.replace(/'/g, "&apos;");
      stringElem = stringElem.replace(/"/g, "&#34;");
    }

    return stringElem;
  }

  // Check wheather the given value is empty or not
  isNullOrEmpty(str: any) {
    return typeof str === "undefined" || str === null || str === ""
      ? true
      : (typeof str === "string" && str.trim().length > 0) ||
        typeof str === "boolean" ||
        typeof str === "object" ||
        typeof str === "number" ||
        typeof str === "function" ||
        this.isDate(str)
      ? false
      : true;
  }

  // Coverting a given string to boolean
  convertToBoolean(value: any) {
    var re1 =
      typeof value === "boolean"
        ? value
        : typeof value === "string"
        ? Boolean(value)
        : false;
    return typeof value === "boolean"
      ? value
      : typeof value === "string"
      ? Boolean(value)
      : false;
  }

  // Check wheather the given object is empty or not
  isEmptyObject(obj: any) {
    return typeof obj === "undefined" || obj === null || obj === ""
      ? true
      : obj && Object.keys(obj).length === 0;
  }

  // Getting parameters from the given url
  getParameterByName(name: any) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(location.search);

    return results == null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  // Check wheather the given array is array or not
  isArray(obj: any) {
    return !!obj && obj.constructor === Array;
  }

  // Move an array from given position to the mentioned position
  arrayMove(arr: any[], fromIndex: number, toIndex: number) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  //Check wheather the number is decimal or not
  isDecimal(num: number) {
    return isNaN(num) ? false : num % 1 === 0 ? false : true;
  }

  //Check wheather a string or object is valid date
  isDate(date: any) {
    return toString.call(date) === "[object Date]" && !isNaN(date);
  }

  // Converting Date Object or Date string to UTC format
  toUTCDateTime(dt: any) {
    var result = "";

    if (!this.isNullOrEmpty(dt)) {
      var dateObj =
        dt instanceof Date ? dt : typeof dt === "string" ? new Date(dt) : dt;

      if (this.isDate(dateObj)) {
        result = dateObj.toUTCString();
      }
    }

    var resultOutput = !this.isNullOrEmpty(result)
      ? result.toLowerCase()
      : result;

    return resultOutput;
  }

  compareObj(x: any, y: any) {
    var self = this;

    if (x instanceof Function) {
      if (y instanceof Function) {
        return x.toString() === y.toString();
      }
      return false;
    }
    if (x === null || x === undefined || y === null || y === undefined) {
      return x === y;
    }
    if (x === y || x.valueOf() === y.valueOf()) {
      return true;
    }

    // if one of them is date, they must had equal valueOf
    if (x instanceof Date) {
      return false;
    }
    if (y instanceof Date) {
      return false;
    }

    // if they are not function or strictly equal, they both need to be Objects
    if (!(x instanceof Object)) {
      return false;
    }
    if (!(y instanceof Object)) {
      return false;
    }

    var p = Object.keys(x);

    return Object.keys(y).every(function (i) {
      return p.indexOf(i) !== -1;
    })
      ? p.every(function (i) {
          let temp: any = self.compareObj(x[i], y[i]);
          return temp;
        })
      : false;
  }

  trim(str: any) {
    if (!this.isNullOrEmpty(str)) {
      str = str.replace(/^\s+|\s+$/gm, "");
      str = str.replace(/\s/g, "");
      str = str.replace(/ /g, "");

      str = str.trim();
    }

    return str;
  }

  sortArray(
    reqArray: any[],
    isAsc?: boolean,
    propName?: string,
    optPropName?: string
  ) {
    if (
      this.isArray(reqArray) &&
      reqArray.length > 0 &&
      !this.isNullOrEmpty(propName) &&
      typeof isAsc !== "undefined"
    ) {
      var self = this;
      let direction = isAsc ? 1 : -1;

      return reqArray.sort(function (a, b) {
        var prop1 =
          (a.hasOwnProperty(propName)
            ? propName
            : !self.isNullOrEmpty(optPropName) && a.hasOwnProperty(optPropName)
            ? optPropName
            : "") || 0;
        var prop2 =
          (b.hasOwnProperty(propName)
            ? propName
            : !self.isNullOrEmpty(optPropName) && b.hasOwnProperty(optPropName)
            ? optPropName
            : "") || 0;

        if (a[prop1] < b[prop2]) {
          return -1 * direction;
        } else if (a[prop1] > b[prop2]) {
          return 1 * direction;
        } else {
          return 0;
        }
      });
    } else {
      return reqArray;
    }
  }

  checkIfArraysEqual(arr1: any, arr2: any) {
    if (arr1.length != arr2.length) {
      return false;
    }
    //sort them first, then join them and just compare the strings
    return arr1.sort().join() == arr2.sort().join();
  }

  validateNumber(number: any) {
    var reg = /^\d+$/;
    var result = reg.test(number);

    return result;
  }

  replaceAll(find: string, replace: string, str: string) {
    // Replace all occurences in a string
    if (!this.isNullOrEmpty(find)) {
      while (str.indexOf(find) > -1) {
        str = str.replace(find, replace);
      }
    }

    return str;
  }
}
